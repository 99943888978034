<template>
  <div class="myopiaComplication">
    <div class="container">
      <div class="oneStyle" v-show = "!showStyle">
        <div class="left">
          <div>
            <p class="title">高度近视引起的黄斑病变：</p>
          </div>
          <div class="typeItem" v-for="item in oneData">
            <p class="prefix">{{item}}</p>
          </div>
        </div>
        <div class="right">
          <div class="rightItem">
            <img src="http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/Resource/WebDemo/pic/HighMyopiaHazard1.jpg" alt="">
            <p>黄斑萎缩</p>
          </div>
          <div class="rightItem">
            <img src="http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/Resource/WebDemo/pic/HighMyopiaHazard2.jpg" alt="">
            <p>黄斑裂孔</p>
          </div>
          <div class="rightItem">
            <img src="http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/Resource/WebDemo/pic/HighMyopiaHazard3.jpg" alt="">
            <p>黄斑出血</p>
          </div>
          <div class="rightItem">
            <img src="http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/Resource/WebDemo/pic/HighMyopiaHazard4.jpg" alt="">
            <p>视网膜脱离</p>
          </div>
        </div>
      </div>
      <div class="twoStyle" v-show = "showStyle">
        <div class="title">{{twoData.title}}</div>
        <img :src="twoData.imgSrc" >
        <div class="typeItem" v-for="item in twoData.typeItem">
          <p>{{item}}</p>
        </div>
      </div>
    </div>
    <div class="radioGroup" @click="radioClick">
      <label for="1"><input type="radio" name="state" id="1" checked value="1"/>黄斑病变</label>
      <label for="2"><input type="radio" name="state" id="2" value="2"/>玻璃体液化</label>
      <label for="3"><input type="radio" name="state" id="3" value="3"/>白内障</label>
      <label for="4"><input type="radio" name="state" id="4" value="4"/>青光眼</label>
      <label  for="5" class="scene" @click="playVideo"><img id="5" src="../../assets/img/drive.png" /></label>
    </div>
    <div class="btnGroup" >
      <button @click="go" style="display: none">返回</button>
    </div>


    <el-dialog
        title=""
        class="sp"
        :visible.sync="showsp"
        width="80%"
        :destroy-on-close="true"
    >
      <video style="width: 100%" controls autoplay="autoplay" loop="loop">
        <source :src="vsrc" type="video/mp4">
        <source :src="vsrc" type="video/ogg">
        您的浏览器不支持Video标签。
      </video>
    </el-dialog>
  </div>
</template>

<script>
// import proFunction from "../proFunction/proFunction";

import pf from "../../publicFn/baseFn"

export default {
  name: "myopiaComplication",
  data() {
    return {
      flag : -1,
      showsp: false,
      vsrc: pf.getUrl('Vedio_shiwangmosiliehuoboli'),
      oneData: [],
      twoData: [],
      twoList: [],
      showStyle: false,
      videoList: []
    }
  },
  methods: {
    radioClick(e) {
      if(e.target.value){
        this.vsrc = this.videoList[e.target.value - 1];
        if(e.target.value == 1) {
          this.showStyle = false;
        }
        else {
          this.showStyle = true;
          this.twoData = this.twoList[e.target.value - 2];
        }
      }
    },
    go() {
      this.$router.go(-1);
    },
    playVideo() {
      this.showsp = true
    },
  },
  created() {
    // console.log(pf.getUrl('Vedio_shiwangmosiliehuoboli'))
    let one = [
      '高度近视的患者随着眼轴变长，视网膜被拉薄，导致影响黄斑区的血液供给和营养输送，时间长了就会营养不良，产生黄斑萎缩变性',
      '黄斑出血是由于黄斑区视网膜因眼周牵拉变薄后，容易缺血，缺氧，从而促进新生血管形成，新生血管形成后，因血管壁脆弱，容易造成出血',
      '高度近视因眼轴不断变成，黄斑被牵拉，容易产生黄斑裂孔。裂孔出现后，严重者会影响视力，甚至出现视网膜脱离。'
    ];
    let twoList = [
      {
        title: '飞蚊症',
        imgSrc: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/Resource/WebDemo/pic/HighMyopiaHazard5.jpg',
        typeItem: [
          '高度近视眼可引起飞蚊症，即眼内玻璃体浑浊液化。',
          '临床表现:',
          '1.眼前会出现半透明飘浮物',
          '2.半透明漂浮物会跟着患者眼睛所看的方向飘动；',
          '3.像只蚊子追着你，怎么甩也甩不掉'
        ]
      },
      {
        title: '白内障',
        imgSrc: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/Resource/WebDemo/pic/HighMyopiaHazard6.jpg',
        typeItem: [
          '高度近视的患者眼内营养代谢不正常，晶状体的囊膜通透性改变，晶状体营养障碍和代谢失常而逐渐发生浑浊，视力逐渐减退产生并发白内障'
        ]
      },
      {
        title: '青光眼',
        imgSrc: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/Resource/WebDemo/pic/HighMyopiaHazard7.jpg',
        typeItem: [
          '研究发现，高度近视眼患者发生开角型青光眼的几率比常人高出6-8倍，约30%人会演变成青光眼，且青光眼导致的视功能往往被高度近视的病变所掩盖，因而容易忽略，造成不可逆的视神经损害。这是由于近视眼眼球增大，眼内的房水流出阻力较大，容易引起眼内增高，青光眼是目前全球排名第二的致盲性眼病'
        ]
      },
    ];
    this.oneData = one;
    this.twoList = twoList;
    this.twoData = twoList[0];
    let videoList = [pf.getUrl('Vedio_shiwangmosiliehuoboli'),
      pf.getUrl('Vedio_feiwenzheng'),
      pf.getUrl('Vedio_baineizhang'),
      pf.getUrl('Vedio_qingguangyan')];
    this.videoList = videoList;
    this.vsrc = this.videoList[0];
  }
}
</script>

<style scoped lang="scss">
.oneStyle {
  margin-top: 20px;
  display: flex;
}
.oneStyle .left {
  width: 35%;
}
.oneStyle .title {
  font-weight: 600;
  font-size: 16px;
  text-align: left;
  text-indent: 1em;
}
.oneStyle .typeItem {
  margin-top: 15px;
}
.prefix {
  text-indent: 2em;
  text-align: left;
  padding-left: 1em;
  padding-right: 1em;
  font-size: 16px;
}
.right {
  width: 65%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.rightItem {
  margin-top: 10px;
  width: 50%;
}
.rightItem img {
  width: 80%;
}

.twoStyle {
  .title {
    margin: 15px;
    font-size: 18px;
    font-weight: 600;
  }
  img {
    width: 45%;
    margin-bottom: 10px;
  }
  .typeItem {
    font: 16px;
    text-align: center;
    display: flex;
    justify-content: center;
    p{
      width: 45%;
    }
  }

}

.radioGroup {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.radioGroup label {
  width: 120px;
}
.radioGroup input {
  margin-right: 5px;
  font-size: 40px;
}
.radioGroup img {
  width: 24px;
  height: 24px;
}
.btnGroup {
  margin-top: 30px;
  align-items: center;
  justify-content: center;
}
.btnGroup button {
  padding: 10px 80px;
  background: transparent;
  border: 1px solid #333;
  color: #333;
  font-size: 18px;
}
</style>
